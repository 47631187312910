import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import HeroImage from "../../components/Shared/HeroImage"
import Logos from "../../components/PageParts/LogoDesigns/Logos"

const LogoDesigns = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Logo Designs - Switchback Creative - Airdrie Logo Design"
        description="Logos are everywhere; we deal with them every day. They instantly elicit emotion, functionality and personality. We embrace the craft of logo design."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <HeroImage
        title="Some Solid Logos"
        subTitle="The Proof is in the pudding as they say, have a look."
        bgImg="branding"
      />
      <Logos />
    </Layout>
  )
}

export const logoDesignQuery = graphql`
  {
    metaImage: file(relativePath: { eq: "switchback-logodesign-meta.jpg" }) {
      publicURL
    }
  }
`

export default LogoDesigns
