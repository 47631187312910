import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Logo from "./Logo"
import LogoRight from "./LogoRight"
import LogoLeft from "./LogoLeft"

const getData = graphql`
  {
    logos: allWordpressWpLogoDesigns(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          id
          slug
          acf {
            _swb_logo_layout_logo_designs {
              __typename
            }
          }
          childWordPressAcfFullWidth {
            logo_colour
            logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          childWordPressAcfThreeLogosLeft {
            big_left_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            big_left_colour
            top_right_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            top_right_colour
            bottom_right_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            bottom_right_colour
          }
          childWordPressAcfThreeLogosRight {
            big_right_colour
            big_right {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            top_left_colour
            top_left {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            bottom_left_colour
            bottom_left {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Logos = () => {
  const data = useStaticQuery(getData)
  const {
    logos: { edges: logos },
  } = data

  return (
    <div>
      <div>
        {logos.map(({ node }) => {
          let layoutType = false
          node.acf._swb_logo_layout_logo_designs.forEach(layout => {
            if (layout.__typename === "WordPressAcf_three_logos_right") {
              layoutType = (
                <LogoRight
                  key={node.id}
                  data={node.childWordPressAcfThreeLogosRight}
                />
              )
            } else if (layout.__typename === "WordPressAcf_full_width") {
              layoutType = (
                <Logo key={node.id} data={node.childWordPressAcfFullWidth} />
              )
            } else if (layout.__typename === "WordPressAcf_three_logos_left") {
              layoutType = (
                <LogoLeft
                  key={node.id}
                  data={node.childWordPressAcfThreeLogosLeft}
                />
              )
            }
          })
          return layoutType && layoutType
        })}
      </div>
    </div>
  )
}

export default Logos
