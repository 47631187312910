import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const LogoStyled = styled.div`
  padding: 10rem 2rem;
  background: ${props => props.bgColor};

  .logo {
    max-width: 30rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const Logo = props => {
  const { data } = props
  return (
    <LogoStyled bgColor={data.logo_colour}>
      <div className="logo">
        <Img
          fluid={data.logo.localFile.childImageSharp.fluid}
          alt={data.logo.alt_text}
        />
      </div>
    </LogoStyled>
  )
}

export default Logo
