import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const BigRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5rem 2rem;
  background: ${props => props.bgColor};

  @media (min-width: 768px) {
    width: 50%;
  }

  .logo {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const LeftSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  .logo-top-left,
  .logo-bottom-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 5rem 2rem;
  }

  .logo-top-left {
    background: ${props => props.topBg};
  }

  .logo-bottom-left {
    background: ${props => props.botBg};
  }

  .logo {
    width: 100%;
    align-self: center;
    max-width: 20rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const LogoLeft = props => {
  const { data } = props
  return (
    <LogoContainer>
      <BigRight className="logo-big-right" bgColor={data.big_left_colour}>
        <div className="logo">
          <Img
            fluid={data.big_left_logo.localFile.childImageSharp.fluid}
            alt={data.big_left_logo.alt_text}
          />
        </div>
      </BigRight>
      <LeftSide topBg={data.top_right_colour} botBg={data.bottom_right_colour}>
        <div className="logo-top-left">
          <div className="logo">
            <Img
              fluid={data.top_right_logo.localFile.childImageSharp.fluid}
              alt={data.top_right_logo.alt_text}
            />
          </div>
        </div>
        <div className="logo-bottom-left">
          <div className="logo">
            <Img
              fluid={data.bottom_right_logo.localFile.childImageSharp.fluid}
              alt={data.bottom_right_logo.alt_text}
            />
          </div>
        </div>
      </LeftSide>
    </LogoContainer>
  )
}

export default LogoLeft
